/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2023 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

"use client";
import React, { useEffect } from 'react';
import LanguageSwitcher from './LanguageSwitcher';
import { Flex, Heading } from '@adobe/react-spectrum';
import Link from 'next/link';

import styles from './footer.module.scss';
import AdobeLogo from '@/assets/adobe-logo.png';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';

const Footer: React.FC = () => {
    const path = usePathname();
    const t = useTranslations();
    const routes: Array<string> = path.split("/");
    useEffect(() => {
    }, [routes[1]])
    useEffect(()=>{
        if (window?.adobePrivacy && window?.OneTrust) {
            window.adobePrivacy.reloadOT();
        }
    })
    return (
        <Flex UNSAFE_className={styles.footer} alignItems={"center"} position={{ L: "fixed", base: "relative" }} bottom={0} left={0} right={0} zIndex={10}>
            <Flex UNSAFE_className={`container ${styles.innerFooter}`} gap={20} alignItems={"center"} justifyContent={{ base: "center", L: "space-between" }} >
                <Flex alignItems={"center"} justifyContent={{ base: "center", L: "start" }} UNSAFE_className={styles.innerFooter} gap={20}>
                    <Flex alignItems={"center"} gap={5}>
                        <Heading level={4}> {t("language")}:</Heading>
                        <LanguageSwitcher />
                    </Flex>
                    <Link className={styles.footerBlock} href="https://community.adobe.com/t5/photoshop-express/ct-p/ct-photoshop-express">
                        {t("user_forums")}
                    </Link>
                    <Link className={styles.footerBlock} href="https://www.photoshop.com/misc/psx-community-guidelines">
                        {t("community_guidelines")}
                    </Link>
                    <span className={`${styles.footerBlock} ${styles.copyright}`}>
                        Copyright © 2023 Adobe. All rights reserved.
                    </span>
                    <Link className={styles.footerBlock} href="https://www.adobe.com/privacy.html">
                        {t("privacy")}
                    </Link>
                    <Link className={styles.footerBlock} href="http://www.adobe.com/go/terms">
                        {t("terms_of_use")}
                    </Link>
                    <Link id={'openCookieModal'} className={styles.footerBlock} href="#">
                        {t("cookie_preferences")}
                    </Link>
                </Flex>
                <Link href="https://www.adobe.com/" >
                    <Flex alignItems={"center"} gap={10}>
                        <Image src={AdobeLogo} alt="Adobe Logo" className={styles.logo} />
                        <Heading level={3}> Adobe </Heading>
                    </Flex>
                </Link>
            </Flex>
        </Flex>
    );
};

export default Footer;
