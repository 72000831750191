"use client";

import {
  Button,
  Tooltip,
  TooltipTrigger,
} from "@adobe/react-spectrum";
import Help from "@spectrum-icons/workflow/Help";
import style from "./help.module.scss";

export default function HelpFunction() {
  
  const handleHelpClick = () => {
    const url = "https://www.adobe.com/in/products/photoshop-express.html";
    window.open(url, "_blank");
  };

  return (
    <>
      <TooltipTrigger>
        <Button
          UNSAFE_className={style.buttonStyle}
          variant="secondary"
          onPress={handleHelpClick}
        >
          <Help />
        </Button>
        <Tooltip>Help</Tooltip>
      </TooltipTrigger>

    </>
  );
}