/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2023 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

'use client';

import { Button, Flex, Heading, Text, View } from '@adobe/react-spectrum';
import Image from 'next/image';
import Link from 'next/link';
import GlobeIcon from '@spectrum-icons/workflow/GlobeGrid'
import Download from '@spectrum-icons/workflow/Download';
import style from './app-bar.module.scss';


import PSXLogo from '@/assets/psx.png';

import { useEffect, useState } from 'react';
import QRModal from '@/components/QRModal/Modal';
import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';
import HelpFunction from '@/components/Help/index';


export default function AppBar() {
    const [modal, setModal] = useState(false);
    const path = usePathname();
    const t = useTranslations("");
    const routes: Array<string> = path.split("/");

    useEffect(() => {

    }, [routes[1]])

    const handleModal = () => {
        const userAgent = navigator.userAgent;
        if (/iPad|iPhone|iPod/i.test(userAgent)) {
            window.location.href = "https://photoshopexpress.app.link/kaSD4e2tMGb";
        }
        else if (/android/i.test(userAgent)) {
            window.location.href = "https://photoshopexpress.app.link/kaSD4e2tMGb";
        } else
            setModal(!modal);
    }

    const isHomeRoute = routes.length === 2;

    return (
        <View borderBottomColor={'gray-300'} borderBottomWidth={'thick'} paddingX={'size-100'} position={'sticky'} isHidden={path.includes("abuse")} top={0} zIndex={10} UNSAFE_className={style.appBar}>
            <Flex alignItems={'center'} justifyContent={'space-between'} height={'size-800'}>
                <Flex alignItems={'center'}>
                    <Link href={`/`}>
                        <Flex alignItems={'center'} gap={'size-100'}>
                            <Image src={PSXLogo} width={35} height={35} alt="Photoshop Express Logo" />
                            <Heading level={1} isHidden={{ base: true, M: false }}>
                                Photoshop Express
                            </Heading>
                        </Flex>
                    </Link>
                </Flex>

                <Flex direction={'row'} alignItems={'center'} gap={'size-100'}>
                    <Link href={'/en/discover/category'}>
                        <Button variant="secondary" isHidden={!isHomeRoute}>
                            <GlobeIcon /> <Text>{t('discover')}</Text>
                        </Button>
                    </Link>
                    <Flex direction={'row'} alignItems={'center'} gap={'size-100'} isHidden={{ base: true, M: false }}>
                    <HelpFunction />
                </Flex>
                    <Button UNSAFE_className={style.headerButton} variant="cta" onPress={handleModal}>
                        <Flex isHidden={{ S: (!isHomeRoute && true), base: true }}>
                            <Download />
                            <Text >{t('get_photoshop_express')}</Text>
                        </Flex>
                        <Text isHidden={!isHomeRoute} UNSAFE_className={style.get_the_apps}>{t("get_the_app")}</Text>
                        <Text isHidden={isHomeRoute}>{t("get_the_app")}</Text>
                    </Button>
                </Flex>
            </Flex>
            <QRModal open={modal} setOpen={handleModal} lang={routes[1]} />
        </View>
    )
}