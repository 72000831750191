/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2023 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

"use client";

import React, { useEffect, useState } from 'react';
import { LANGUAGE_EXPANDED_MAP } from '@/constants/constants';
import { DialogTrigger, ActionButton, Item, Menu } from '@adobe/react-spectrum';
import { useRouter, usePathname } from "next/navigation";
import styles from './LanguageSwitcher.module.scss';
import type { Selection } from '@adobe/react-spectrum';


const LanguageSwitcher: React.FC = () => {
    const [selectedLanguage, setSelectedLanguage] = useState<Selection>(new Set(['en']));
    const router = useRouter();
    const abc = usePathname();
    const parts = abc.split('/');

    const composeUrlWithNewLocale = (locale) => {
        if (parts.length >= 2) {
            parts[1] = locale;
        }
        const partsUnited = parts.join('/');
        return partsUnited;

    }

    useEffect(() => {
        if (abc.length > 1) {
            setSelectedLanguage(new Set([parts[1]]));
        }
    }, [])

    const onChange = (locale) => {
        const selectedLocaleArray = Array.from(locale);
        if (!Array.isArray(selectedLocaleArray) || selectedLocaleArray.length === 0) return;
        const localeSelected = selectedLocaleArray[0];
        setSelectedLanguage(locale);
        const url = composeUrlWithNewLocale(localeSelected);
        const parts = url.split('/');
        const dynamicURL = `/${parts.slice(1).join('/')}`;
        router.push(dynamicURL);
    }

    let menuItems = Object.keys(LANGUAGE_EXPANDED_MAP).map(lng => {
        return {
            name: lng
        }
    });

    const selectedLanguageArray = Array.from(selectedLanguage);
    const language = selectedLanguageArray[0];

    return (
       
            <DialogTrigger type="popover" mobileType="tray">
                <ActionButton isQuiet UNSAFE_className={styles.langBtn}>
                    <div className={styles.languageInput}>
                        {LANGUAGE_EXPANDED_MAP[language]}
                    </div>
                </ActionButton>
                {(close) => (
                    <Menu
                        selectionMode="single"
                        selectedKeys={selectedLanguage}
                        onSelectionChange={(keys) => {
                            onChange(keys);
                            setTimeout(() => close(), 100);
                        }}
                        items={menuItems}
                    >
                        {item => <Item key={item.name}>{LANGUAGE_EXPANDED_MAP[item.name]}</Item>}
                    </Menu>
                )}

            </DialogTrigger>
       
    );

};

export default LanguageSwitcher;